import React from "react";
import dataSuccess from "../../data/dataSuccess";
import InfoHoverBlock from "./InfoHoverBlock";
import dataMPRcolors from "../../data/dataMPRcolors";

class PrimeBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   showPicker: false
    };
  }

  render() {
    const MaPrimeRenov = this.props.APIresult["Montant MPR unitaire (€)"];
    const PrimeHellio = this.props.APIresult["Montant prime CEE unitaire (€)"];

    return (
      <div>
        {/* {this.props.APIresult["Montant total prime (€)"]} / {PrimeHellio} / {MaPrimeRenov} */}
        {PrimeHellio !== null && (
          <div
            className={
              MaPrimeRenov || this.props.resultCodePromo.data.montant_promo
                ? "success-item-right no-border"
                : "success-item-right"
            }
          >
            <div>
              <InfoHoverBlock
                data={this.props.APIresult}
                success={this.props.success}
                item="Prime1"
              ></InfoHoverBlock>
            </div>
            <div>
              <h5 className="h5-prime">
                - {PrimeHellio == null ? "0" : PrimeHellio} €{" "}
              </h5>
            </div>
          </div>
        )}

        {MaPrimeRenov !== null && (
          <div>
            <div className="success-item-right no-border">
              <div>
                <InfoHoverBlock
                  data={this.props.APIresult}
                  success={this.props.success}
                  item="Prime2"
                ></InfoHoverBlock>
              </div>
              <div>
                <h5 className="h5-prime" style={{ fontSize: "20px" }}>
                  - {MaPrimeRenov == null ? "0" : MaPrimeRenov} €{" "}
                </h5>
              </div>
            </div>
            <div className="w-100">
              {this.props.item !== "Prime1" && (
                <div className="success-item-right ">
                  <p className="text-sub2 paragraph">
                    <strong>MaPrimeRénov'</strong> est une aide publique dont le
                    montant varie en fonction des revenus et du type de travaux.{" "}
                    <strong>MaPrimeRénov'</strong> n’est pas pris en charge par
                    Sorégies.{" "}
                    <a
                      href="https://www.maprimerenov.gouv.fr/"
                      className="text-sub2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Voir le site.
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {this.props.resultCodePromo.data.montant_promo && (
          <div className="success-item-right">
            <div className="info-block-container">
              <p className="p">Code Promo</p>
            </div>
            <div>
              <h5 className="h5-prime">
                - {this.props.resultCodePromo.data.montant_promo} €
              </h5>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PrimeBlock;
