import React from "react";
import infoIcon from "../../images/info.svg";
import dataSuccess from "../../data/dataSuccess";
class InfoHoverBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  showHover = () => {
    this.setState({ display: true });
  };

  hideHover = () => {
    this.setState({ display: false });
  };

  toggleClick = () => {
    this.setState({ display: !this.state.display });
  };

  render() {
    const MaPrimeRenov = this.props.data["Montant MPR unitaire (€)"];
    const PrimeHellio = this.props.data["Montant prime CEE unitaire (€)"];
    const MaPrimeRenovInfo = `est une aide publique dont le montant varie en fonction des revenus et du type de travaux.`;
    const PrimeHellioInfo = `est versée par les fournisseurs d'énergie, obligés de financer des opérations d'économies d'énergie.`;
    if (MaPrimeRenov == null && PrimeHellio == null) {
      return <div></div>;
    } else {
      return (
        <div>
          <div
            className="info-block-container"
            onMouseEnter={(e) => this.showHover(e)}
            onMouseLeave={(e) => this.hideHover(e)}
          >
            <p className={this.props.item == "Prime1" ? "p" : "p2"}>
              <span>
                {this.props.item == "Prime1"
                  ? "Prime Sorégies"
                  : "MaPrimeRénov'"}
              </span>
              {/* <img src={infoIcon} alt="info"/> */}
              <span className="iconColor2">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.5"
                    cy="7.5"
                    r="7"
                    fill="white"
                    stroke="#006FBA"
                  />
                  <path
                    d="M7.81726 3C8.00677 3 8.15905 3.05865 8.27411 3.17594C8.38917 3.28633 8.4467 3.43122 8.4467 3.61061C8.4467 3.79 8.38917 3.93834 8.27411 4.05563C8.15905 4.16602 8.00677 4.22122 7.81726 4.22122C7.63452 4.22122 7.48562 4.16602 7.37056 4.05563C7.26227 3.93834 7.20812 3.79 7.20812 3.61061C7.20812 3.43122 7.26227 3.28633 7.37056 3.17594C7.48562 3.05865 7.63452 3 7.81726 3ZM8.49746 10.3066H10V11H6V10.3066H7.64467V6.23933H6.05076V5.54593H8.49746V10.3066Z"
                    fill="#006FBA"
                  />
                </svg>
              </span>
            </p>
            <div
              className="info-block"
              style={{ display: this.state.display ? "block" : "none" }}
            >
              <div>
                {this.props.item == "Prime1" && (
                  <p>
                    <strong>La prime Sorégies</strong> : {PrimeHellioInfo}
                  </p>
                )}
                {this.props.item == "Prime2" && (
                  <p>
                    <strong>MaPrimeRénov'</strong> : {MaPrimeRenovInfo}
                  </p>
                )}

                {this.props.item == "CodePromo" && (
                  <p>
                    <strong>Code Promo</strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default InfoHoverBlock;
