import React from "react";
import ReactDOM from "react-dom/client";
import { UserProvider } from "components/UserContext";
import App from "App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import your route components too
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserProvider>
      <Routes>
        <Route index element={<App />} />
        <Route path="*" element={<App />}></Route>
        {/* <Route path="*" element={<App id='Q1' />} /> */}
      </Routes>
    </UserProvider>
  </BrowserRouter>,
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
