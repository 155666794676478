import setMarque from "data/setMarque";

/**
 * Retourne l'environnement à utiliser en fonction de l'URL du front
 * @returns {object}
 */
export default function getEnvironment() {
  const environmentConfig = {
    brand: "default", // Nom de la marque utilisée
    environment: "local", // Nom de l'environnement utilisé
    apiUrl: "http://localhost:8080", // URL vers le back-end
    simulatorUrl: "http://localhost:3001", // URL du front-end
    customerAccountUrl: "http://localhost:3000", // URL de l'espace bénéficiaire
    apiToken: null, // Token pour l'API
    cryptoKey: null, // Clé de cryptage
  };

  // Détection de la configuration en fonction de l'URL
  for (const brandKey of Object.keys(setMarque)) {
    const brandEnvironments = setMarque[brandKey].environments;

    // Match sur l'environnement (dev, prod etc)
    let envMatch = false;
    if (brandEnvironments) {
      for (const environmentKey of Object.keys(brandEnvironments)) {
        const envConfig = brandEnvironments[environmentKey];
        if (window.location.href.includes(envConfig.simulatorUrl)) {
          envMatch = true;
          Object.assign(environmentConfig, envConfig, {
            environment: environmentKey,
            brand: brandKey,
          });
        }
      }
    }

    // Match de la marque sur le hostname
    if (!envMatch && window.location.hostname === brandKey) {
      environmentConfig.environment = "custom";
      environmentConfig.brand = brandKey;
      environmentConfig.simulatorUrl = window.location.origin;
    }
  }

  // Surcharge de la configuration à l'aide des variables d'environnement
  if (process.env.REACT_APP_API_URL) {
    environmentConfig.environment = "custom";
    environmentConfig.apiUrl = process.env.REACT_APP_API_URL;
  }
  if (process.env.REACT_APP_API_CRYPTO_KEY) {
    environmentConfig.environment = "custom";
    environmentConfig.cryptoKey = process.env.REACT_APP_API_CRYPTO_KEY;
  }
  if (process.env.REACT_APP_API_TOKEN) {
    environmentConfig.environment = "custom";
    environmentConfig.apiToken = process.env.REACT_APP_API_TOKEN;
  }

  return environmentConfig;
}
