import React from "react";
import Navigation from "./Navigation";
class QuestionsCheckbox extends React.Component {
  render() {
    // console.log('Data',this.props.data)

    const itemsMobile = this.props.dataItems[this.props.name].items.map(
      (item) => (
        <div className="item-container-m" key={item.long}>
          <label>
            <div
              className={
                this.props.data.includes(item.short)
                  ? "item item-checked"
                  : "item"
              }
            >
              <img src={item.icon} alt={item.short} width="30" height="35" />{" "}
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="item-container" key={item.long}>
        <label>
          <div
            className={
              this.props.data.includes(item.short)
                ? "item item-checked"
                : "item"
            }
          >
            <img src={item.icon} alt={item.short} />
            <span className="mt-10">{item.long}</span>
          </div>
          <div className="d-flex mb-30">
            <div>
              <div>
                <input
                  type="checkbox"
                  name={this.props.name}
                  value={item.short}
                  onChange={this.props.handleData}
                  className="is-hidden"
                />
              </div>
              {/* 
                <div className={this.props.data.includes(item.short)? 'square-checked':'square'}>
                  <svg width="16" height="11" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.45312 5.59251L5.27343 9.69831L14.2833 2.03833" stroke="white" strokeWidth="3"></path></svg>
                </div> */}
            </div>
            {/* <div className={item.long.length > 18? 'lh16':''}>
                <span>{item.long}</span>                
              </div> */}
          </div>
        </label>
      </div>
    ));

    return (
      <div className="fadeIn">
        {/* <div className={this.props.dataItems[this.props.name]["items"].length > 3? 'item-wrapper': 'item-wrapper jcc'}>
          {items}
        </div> */}
        <div
          className={
            this.props.dataItems[this.props.name].items.length > 3
              ? "item-wrapper is-hidden-mobile"
              : "item-wrapper jcc is-hidden-mobile"
          }
        >
          {items}
        </div>

        <div className="only-show-mobile">{itemsMobile}</div>

        <Navigation
          data={this.props.data.length !== 0}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
        />
      </div>
    );
  }
}

export default QuestionsCheckbox;
