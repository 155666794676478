import React from "react";
import iconCheck from "../images/icons/checkm.svg";
const LauraIMG = "https://hellio.com/hubfs/simulateur/images/personLaura.png";

class ProgressBar extends React.Component {
  render() {
    const stepPercent =
      ((this.props.step + 1) / this.props.stepList.length) * 100;

    if (
      this.props.currentStepName == "Success" ||
      this.props.currentStepName == "Q38"
    ) {
      return (
        <div>
          <div
            className="inner-container"
            style={{ paddingBottom: "7px", width: "930px" }}
          >
            <div className="row">
              <div
                className="col progress-success-container align-center"
                style={{ flexGrow: 1 }}
              >
                <h2
                  className="text-s is-hidden-mobile align-left is-normal"
                  style={{ lineHeight: "1.1em" }}
                >
                  Simulation terminée
                </h2>
                <div className="progress-bar-container mt-10">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: stepPercent + "%" }}
                    ></div>
                  </div>
                  <div className="icon-check success-icon-check">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.705 2.54008C14.92 1.03008 12.58 0.0800781 10 0.0800781C7.42 0.0800781 5.08 1.03008 3.295 2.54008C1.27 4.36508 0 7.02508 0 9.96008C0 13.6101 1.905 16.7451 4.8 18.5301C6.31 19.4051 8.095 19.9201 10 19.9201C11.905 19.9201 13.69 19.4051 15.2 18.5301C18.095 16.7851 20 13.6101 20 10.0001C20 7.02508 18.73 4.36508 16.705 2.54008ZM8.375 15.2001L3.495 10.3601L4.605 9.25008L8.375 12.9401L15.515 5.95008L16.625 7.06008L8.375 15.2001Z"
                        fill="#006FBA"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div className='only-show-mobile progress-reset'>
              <button className="btn-init-m" onClick={this.props.reset}>
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.23922 7.81501C2.69286 7.62507 3.21458 7.83883 3.40453 8.29247L4.55782 11.0468L7.34005 9.63521C7.77863 9.41269 8.31455 9.58784 8.53707 10.0264C8.75959 10.465 8.58444 11.0009 8.14586 11.2234L3.65504 13.5019L1.76176 8.98032C1.57182 8.52668 1.78558 8.00496 2.23922 7.81501Z" fill="white"/>
                <path d="M4.55766 11.0859C4.94473 9.47174 5.90046 7.98851 7.36549 6.96185C10.5519 4.72891 14.9473 5.50222 17.1803 8.6886C19.4132 11.875 18.6399 16.2704 15.4535 18.5034C12.5517 20.5369 8.64945 20.0772 6.28819 17.5733C6.0554 17.3262 5.83814 17.0612 5.63874 16.7766" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                </svg>                
              </button>
            </div> */}
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div>
          <div className="inner-container">
            <div className="row d-flex ph-5">
              <div
                className="col pt-10 align-center w-100"
                style={{ flexGrow: 1 }}
              >
                {/* <h2 className="text-main is-bold text-m is-hidden-mobile" style={{'lineHeight':'1.1em'}}>
              Estimez le montant de votre projet en quelques clics
              </h2> */}
                <div
                  className="only-show-mobile d-flex align-left"
                  style={{ lineHeight: "1.1em" }}
                >
                  {/* <img
                src={LauraIMG}
                alt="greetings"
                width="45"
                height="45"
                className="mr-20"/> */}
                  {/* <p className='mt-5 text-white'>Estimez le montant de votre projet en quelques clics</p> */}
                </div>
                <div className="progress-bar-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: stepPercent + "%" }}
                    ></div>
                  </div>
                  <p className="">
                    {this.props.step + 1}/{this.props.stepList.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default ProgressBar;
