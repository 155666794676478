import React from "react";
import Navigation from "./Navigation";
import arrowDown from "../images/arrow-down.svg";

class QuestionsDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const ItemMinHeight = `${this.props.dataItems[this.props.name].minHeight}`;
    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="item-detail-container" key={item.long}>
        <label>
          <div
            className={
              this.props.data == item.short ? "item item-checked" : "item"
            }
            style={{ minHeight: ItemMinHeight }}
          >
            <div className="img-container">
              <h1>{item.long}</h1>
              <img src={item.img} alt={item.long} className="w-100" />
            </div>
            {/* <div
              className={
                this.props.data == item.short ? 'radio-checked' : 'radio'
              }
            >
              <div className="radio-inner"></div>
            </div> */}
            <div className="d-flex flex-start p-20">
              <img src={item.icon} alt={item.short} width="65" />
              <div className="ph-20">
                <p>{item.text}</p>
              </div>
            </div>
          </div>
          <div className="d-flex mb-30">
            <div>
              <div>
                <input
                  type="radio"
                  name={this.props.name}
                  value={item.short}
                  onChange={this.props.handleData}
                  className="is-hidden"
                />
              </div>
            </div>
          </div>
        </label>
      </div>
    ));

    const itemsMobile = this.props.dataItems[this.props.name].items.map(
      (item) => (
        <div className="item-detail-container" key={item.long}>
          <label>
            <div
              className={
                this.props.data == item.short ? "item item-checked" : "item"
              }
            >
              <h2 className="d-flex is-normal">
                <img
                  src={item.icon}
                  alt={item.short}
                  width="30"
                  style={{ verticalAlign: "middle", marginRight: "10px" }}
                />
                {item.long}
                <span style={{ marginLeft: "auto" }}>
                  {this.props.data !== item.short && (
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 10L6 6L2 2"
                        stroke="#C6C2BE"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                    </svg>
                  )}
                  {this.props.data == item.short && (
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 2L6 6L10 2"
                        stroke="#006FBA"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                    </svg>
                  )}
                </span>
                {/* <img
                  src={arrowDown}
                  alt="icon"
                  className={
                    this.props.data == item.short
                      ? 'align-center rotate180'
                      : 'align-center'
                  }
                /> */}
              </h2>
              {this.props.data == item.short && (
                <div>
                  <img src={item.img} alt={item.long} className="w-100 mt-20" />
                  <p className="mt-10">{item.text}</p>
                </div>
              )}
            </div>
            <div className="d-flex mb-10">
              <div>
                <div>
                  <input
                    type="radio"
                    name={this.props.name}
                    value={item.short}
                    onChange={this.props.handleData2}
                    className="is-hidden"
                  />
                </div>
              </div>
            </div>
          </label>
        </div>
      ),
    );

    return (
      <div className="fadeIn">
        <div
          className={
            this.props.dataItems[this.props.name].items.length > 3
              ? "d-flex is-hidden-mobile"
              : "d-flex jcc is-hidden-mobile"
          }
        >
          {items}
        </div>

        <div className="only-show-mobile">{itemsMobile}</div>

        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
        />
      </div>
    );
  }
}

export default QuestionsDetails;
