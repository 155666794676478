import React from "react";
import UserContext from "components/UserContext";
import Main from "components/Main";
import "App.scss";
import TagManager from "react-gtm-module";
import setMarque from "data/setMarque";
import dataItems from "data/dataItems";
import getEnvironment from "utils/getEnvironment";

const { brand: BRAND } = getEnvironment();

const tagManagerArgs = {
  gtmId: "GTM-P39RH8J",
  dataLayerName: "simulateurGTM",
};

TagManager.initialize(tagManagerArgs);

class App extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.modifyCookieBannerContent();
  }

  modifyCookieBannerContent() {
    const policyWording = document.querySelector("#hs-eu-policy-wording");
    if (policyWording) {
      policyWording.innerHTML = `
        <p>Soregies utilise des cookies permettant de nous souvenir de vous afin d'améliorer et de personnaliser votre navigation, ainsi que pour l'analyse et les indicateurs concernant nos visiteurs à la fois sur ce site web et sur d'autres supports. Pour en savoir plus, consultez notre <a href="https://www.soregies.fr/politique-de-confidentialite/" target="_blank">politique de confidentialité</a>.</p>
      `;
    }
  }

  render() {
    const { embed } = this.context;
    const color1 = setMarque[BRAND].color1;
    const color2 = setMarque[BRAND].color2;
    const logoMarque = setMarque[BRAND].logoMarque;
    const logoMarqueWidth = setMarque[BRAND].logoMarqueWidth;
    const logoMarquePadding = setMarque[BRAND].logoMarquePadding;
    const logoEtreRappele = setMarque[BRAND].logoEtreRappele;
    const logoLink = setMarque[BRAND].logoLink;
    const logoAvis = "https://hellio.com/hubfs/simulateur/marque/logo-avis.svg";
    const showHeader = setMarque[BRAND].showHeader;
    const headerBgcolor = setMarque[BRAND].headerBgcolor
      ? setMarque[BRAND].headerBgcolor
      : "white";
    const arrayQ1 = setMarque[BRAND].removeQ1;
    const dataItemsUpdate = dataItems;
    const displayAvis = setMarque[BRAND].avisVerifies;
    const marque =
      setMarque[BRAND].marque == "Hellio" ? undefined : setMarque[BRAND].marque;
    const favicon = setMarque[BRAND].favicon;
    const homeLink = "https://hellio.com/";
    const originalURL = BRAND == "default";
    // Add favicon from setMarque
    const link = document.querySelector("link[rel~='icon']");
    const metaRobots = document.querySelector("meta[name='robots']");
    link.href =
      favicon || "https://www.hellio.com/hubfs/Hellio_Logo_Bleu_Favicon.png";
    metaRobots.content = originalURL ? "all" : "noindex, nofollow";
    // Remove certain options from Q1 :
    if (arrayQ1) {
      const dataItemsQ1 = dataItems.Q1.items.filter(function (item) {
        return !arrayQ1.includes(item.id);
      });
      dataItemsUpdate.Q1.items = dataItemsQ1;
    }

    const css = `

  .calculette .header2 {
    background-color: ${headerBgcolor};
  }
  .text-main {
      color: ${color1}!important;
  }

  .success-item-right .h5-prime {
    background-color: ${color1};
  }

  .creneau-btn {
    color: ${color2}
  }

  .react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: ${color1};
  }

  .final-container h2 {
    color: ${color1}
  }
  .popup-container {
    background-color: ${color2};
  }
  .btn-sub {
    color: ${color2}!important;
  }

  .btn-sub:hover {
    background-color: ${color2}!important;
    color: white!important;
  }
  .calculette .progress-bar-container .progress-bar {
    background-color: ${color2};
  }
  .success-mobile-header {
    background-color: ${color2};
  }

  .btn-nav-m {
    background-color: ${color2};
  }

  .item-container-m .item-checked {
    border: 1px solid ${color2};
  }

  @media screen and (max-width:767px) {
    .calculette .progress-bar-container .progress-bar {
      background-color: ${color2};
    }
  }

  .calculette .text-sub2 {
    color: ${color2}
  }
  .calculette .btn-aller {
      background-color: ${color2};
      border: 3px solid ${color2};
  }

  .item-container .item-checked {
    border: 2px solid ${color2}!important;
  }

  .square-checked {
    background: ${color1};
    border: 1px solid ${color1};
  }

  .radio-rounded-checked {
    background-color: ${color2};
    border: 1px solid ${color2}!important;
  }

.radio-oval-checked {
    background-color: ${color1};
    border: 1px solid ${color1}!important;
  }

  .radio-oval:hover{
    border: 1px solid ${color1};
  }

  .success-right-container .successTitle {
    color: ${color1}
  }

  .success-right-container h3 {
    color: ${color1}
  }

  .btn-main-hoverlarge {
    background-color: ${color2};
    border-color: ${color2}
  }

  .input-container input {
    color: ${color1};
  }

  .btn-retour svg path{
    stroke: ${color2}
  }
  .icon-check svg path {
    fill: ${color2}
  }

  .info-block-container {
    color: ${color1}
  }
  .info-block{
    background-color: ${color1}
  }

  .info-block:before {
    border-right: 8px solid ${color1};
  }

  .item-detail-container .radio, .item-detail-container .radio-checked, .item-detail-container .item-checked {
    border: 1px solid ${color2};
  }
.item-detail-container .radio-checked .radio-inner {
    background-color: ${color2}
}

.vertical-borne-list .list-item-l h1 {
  color: ${color1};
}

.vertical-borne-list .list-bullet, .vertical-borne-list .vline-main {
  background-color: ${color1}
}

.iconColor1retour svg path, .iconColor1retour svg rect {
  stroke: ${color2}
}
.iconColor1 svg path, .iconColor1 svg g path{
fill: ${color1}
}
.iconColor1 svg circle {
  stroke:  ${color1}
}

.iconColor2 svg path{
  fill: ${color2}
}

.iconColor2 svg circle {
  stroke:  ${color2}
}

.btn-file-uploader{
  color: ${color1}
}

.success-item h3,  .success-item h2{
 color: ${color1}
}

.title-left {
  color: ${color1}
}

.item-container-Q45 .item-checked {
  border-color: ${color2};
}

.h5-price {
  color: ${color1}
}

.success-right-container h5 {
  color: ${color1}
}

.navigation-general .wrap {
  width: ${embed ? "90vw" : "100vw"};
  max-width:${embed ? "900px" : "70vw"}
}
`;

    return (
      <div className="calculette">
        <style>{css}</style>
        <div className="wrapper">
          <header className={embed ? "is-hidden" : "header2"}>
            {logoMarque && (
              <div>
                {logoLink && (
                  <a href={logoLink} target="_blank" rel="noreferrer">
                    <img src={logoMarque} className="logoMarque" />
                  </a>
                )}
                {!logoLink && <img src={logoMarque} className="logoMarque" />}

                <p
                  className={
                    headerBgcolor === "white"
                      ? "text-main header-text"
                      : "header-text"
                  }
                >
                  Estimez le coût <br className="is-hidden-tablette" />
                  de votre projet !
                </p>
                {/* <a href={homeLink} target="_blank"
              rel="noreferrer"><img src={headerBgcolor === 'white'? '/logo-blue.svg' : "/logo-white.svg"} className="logoHeader" /></a> */}
              </div>
            )}

            {!logoMarque && (
              <div className="default-header">
                <p>
                  Estimez le coût <br className="is-hidden-tablette" />
                  de votre projet !
                </p>
                <a href={homeLink} target="_blank" rel="noreferrer">
                  <img
                    src={
                      headerBgcolor === "white"
                        ? "/logo-blue.svg"
                        : "/logo-white.svg"
                    }
                    className="logoHeader is-hidden-tablette"
                  />
                </a>
                <a href={homeLink} target="_blank" rel="noreferrer">
                  <img
                    src={
                      headerBgcolor === "white"
                        ? "/logo-blue.svg"
                        : "/logo-white.svg"
                    }
                    className="logoHeaderDefault only-show-tablette"
                  />
                </a>
              </div>
            )}

            {/* <div className="container">
          <div className='is-hidden-mobile'>
            <div className="logo-center">
                <a href="https://particulier.hellio.com/" target="_blank" rel="noreferrer"><img src="/hellio-logo.svg" /></a>
            </div>
            <div className="d-flex">
                <img src={logoMarque} style={{maxHeight:'60px', 'padding': logoMarquePadding, 'width': logoMarqueWidth}} />
            </div>
            <div className="logo-avis" style={{'display': displayAvis? 'block':'none'}}>
                <img src="/logo-avis.svg" />
            </div>
          </div>
          <div className="only-show-mobile header-m">
            <a href="https://particulier.hellio.com/" target="_blank" rel="noreferrer">
                <img src="/hellio-logo.svg" style={{maxWidth: '80px'}}/>
            </a>
            <img src={logoMarque? logoMarque: displayAvis? logoAvis : ''} style={{'maxHeight': '40px', 'marginTop': '-5px'}}/>
          </div>
        </div> */}
          </header>
          <div className={embed ? "is-hidden" : "header2s"}></div>
          <main className="main-container">
            <div className="content-container">
              <Main
                logoEtreRappele={logoEtreRappele}
                dataItems={dataItemsUpdate}
                marque={marque}
              />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default App;
