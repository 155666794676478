import React from "react";
import dataSuccess from "../../data/dataSuccess";
import dataPhotovoltaique from "../../data/dataPhotovoltaique";
// import retourWhite from '../../images/icons/retourWhite.svg';
import NavSuccess from "./NavSuccess";
import SuccessTemplateSidebar from "./SuccessTemplateSidebar";
import FileUploaderAuto from "./FileUploaderAuto";
class SuccessPhotovoltaique extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const offre = this.props.result.data.Offre;
    const panneaux = dataPhotovoltaique[offre].panneaux;
    const packname = dataPhotovoltaique[offre].packName;
    const pack = dataPhotovoltaique[offre].pack;
    const price = this.props.result.data["Coût travaux unitaire (€)"];
    const monthly = this.props.result.data["Coût travaux unitaire mensuel (€)"];
    const prime = this.props.result.data["Montant total prime (€)"];
    const reste = this.props.result.data["Reste à charge unitaire (€)"];
    const montantPromo = this.props.resultCodePromo.data.montant_promo;
    const codePromoReste = this.props.resultCodePromo.data.reste_a_charge;
    const bgMobile = `url(${dataSuccess[this.props.success].imgMobile})`;
    const paragraphSolaire =
      "*Cette prime est versée automatiquement par EDF Obligation d'Achat en même temps que vos revenus générés par la vente de votre surplus sur la première année.";
    return (
      <div className="flex-container">
        <SuccessTemplateSidebar
          success={this.props.success}
          lastStep={this.props.lastStep}
          codePromo={this.props.codePromo}
          handleCodePromo={this.props.handleCodePromo}
          validCodePromo={this.props.validCodePromo}
          resultCodePromo={this.props.resultCodePromo}
        >
          <div className="success-item">
            <h3>Type de logement</h3>
            <h2>{this.props.Q2}</h2>
          </div>

          {this.props.facture && (
            <div className="success-item">
              <h3>Facture d’électricité / mois</h3>
              <h2>{this.props.facture} €</h2>
            </div>
          )}
          <div className="success-item">
            <h3>Type de contrat</h3>
            <h2>{this.props.Q16}</h2>
          </div>
        </SuccessTemplateSidebar>

        <div className="success-right-container">
          <p className="text-s fw-300 mt-10">
            Recommandation : Pack {packname} {pack} kWc{" "}
          </p>
          <div className="success-item-right">
            <h5>Prix pour {panneaux} panneaux solaires</h5>
            <div>
              <h5 className="h5-price">{price} € </h5>
              <h6
                className="text-main align-right"
                style={{ fontSize: "14px" }}
              >
                ou {monthly}€/mois
              </h6>
            </div>
          </div>
          <div className="success-item-right">
            <div>
              <h2
                className="text-main"
                style={{ fontSize: "24px", lineHeight: "26px" }}
              >
                Prime à l’autoconsommation*
              </h2>
            </div>

            <div>
              <h5 className="h5-prime">- {prime} € </h5>
            </div>
          </div>

          {montantPromo && (
            <div className="success-item-right">
              <div>
                <h2
                  className="text-main"
                  style={{ fontSize: "24px", lineHeight: "26px" }}
                >
                  Code Promo
                </h2>
              </div>

              <div>
                <h5 className="h5-prime">- {montantPromo} € </h5>
              </div>
            </div>
          )}

          <div className="success-item-right last">
            <h5>Reste à payer</h5>
            <div>
              {!codePromoReste && <h5 className="h5-price"> {reste} € </h5>}
              {codePromoReste && (
                <h5 className="h5-price"> {codePromoReste} € </h5>
              )}
            </div>
          </div>
          {this.props.showFile && (
            <FileUploaderAuto
              patchName="fileImposition"
              description="Déposez votre avis imposition pour valider le montant de votre prime"
              handleFile={this.props.handleFile}
              result={this.props.result}
            ></FileUploaderAuto>
          )}

          <NavSuccess
            callBtn={this.props.callBtn}
            lastStep={this.props.lastStep}
            date={this.props.date}
            creneau={this.props.creneau}
            handleDate={this.props.handleDate}
            handleCreneau={this.props.handleCreneau}
          ></NavSuccess>

          <p className="text-s text-main mb-5">
            Hellio s’occupe de toutes vos démarches administratives.
          </p>
          <p className="paragraph">{paragraphSolaire}</p>
        </div>
        <div
          style={{
            backgroundImage: bgMobile,
          }}
          className="success-header-img only-show-mobile"
        >
          <h1>{dataSuccess[this.props.success].h1}</h1>
          <button className="btn-init-success" onClick={this.props.reset}>
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4697_13755)">
                <path
                  d="M13.3083 2.97206C13.4457 3.18957 13.5756 3.38159 13.6888 3.57816C14.2172 4.47858 14.5306 5.47976 14.6065 6.50979C14.6824 7.53982 14.519 8.57316 14.1279 9.53559C13.7368 10.498 13.1278 11.3657 12.3446 12.0762C11.5613 12.7868 10.6234 13.3227 9.59799 13.6453C5.59089 14.9267 1.21656 12.6169 0.214785 8.69046C-0.741326 4.9419 1.57998 1.21063 5.481 0.226846C6.06199 0.0832859 6.65906 0.0078038 7.25908 0.00205981C7.63201 -0.00385562 7.90886 0.236857 7.91504 0.567211C7.92123 0.897565 7.64961 1.14237 7.27478 1.14874C5.44485 1.17969 3.90604 1.83903 2.7021 3.15316C1.51766 4.44546 1.02011 5.97073 1.2275 7.67892C1.54716 10.2899 3.55832 12.3162 6.2497 12.7839C9.54566 13.3559 12.7741 11.1945 13.3373 8.03476C13.6033 6.54649 13.319 5.16031 12.4844 3.87621C12.4402 3.80796 12.3921 3.74198 12.3198 3.63777C12.3079 3.81023 12.297 3.94265 12.2903 4.07551C12.277 4.34398 12.268 4.61336 12.2532 4.88183C12.2511 4.95684 12.2333 5.03068 12.2009 5.099C12.1685 5.16732 12.1221 5.22874 12.0644 5.27963C12.0068 5.33052 11.9391 5.36986 11.8653 5.39532C11.7915 5.42078 11.7132 5.43185 11.6348 5.42787C11.5576 5.42661 11.4813 5.41054 11.4106 5.38061C11.3399 5.35069 11.2762 5.30751 11.2233 5.25364C11.1704 5.19977 11.1292 5.13629 11.1024 5.06696C11.0755 4.99763 11.0634 4.92385 11.0669 4.84998C11.0987 3.97222 11.1344 3.09446 11.1806 2.21716C11.2024 1.79853 11.4845 1.58785 11.9378 1.642C12.6904 1.73301 13.4415 1.83266 14.1935 1.92867C14.2986 1.94187 14.4042 1.95324 14.5084 1.97144C14.8266 2.02605 15.0274 2.28997 14.9931 2.60348C14.9786 2.74429 14.9078 2.8743 14.7955 2.96638C14.6832 3.05846 14.5381 3.10549 14.3904 3.09765C14.095 3.07945 13.8011 3.0303 13.5066 2.99481C13.4524 2.98753 13.3948 2.98162 13.3083 2.97206Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_4697_13755">
                  <rect width="15" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default SuccessPhotovoltaique;
