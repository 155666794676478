import React from "react";
import UserContext from "components/UserContext";
import "./Greeting.scss";
// import dataItems from "../data/dataItems";
// const LauraIMG = "https://hellio.com/hubfs/simulateur/images/personLaura.png";
class Greeting extends React.Component {
  static contextType = UserContext;
  render() {
    const isEauEau = this.props.answers.Q45 === "PAC eau/eau";

    // console.log('props', this.props.nonPrimeHellio)
    return (
      <div
        className={
          this.props.dataItems[this.props.currentStepName].greetingSide
            ? "m-auto greeting-container"
            : "m-auto greeting-container"
        }
      >
        {/* <img
                src={LauraIMG}
                alt="greetings"
                width="75"
                className="mr-20 is-hidden-mobile"/> */}
        <div>
          <h3
            className="text-main"
            dangerouslySetInnerHTML={{
              __html: this.props.dataItems[this.props.currentStepName].greeting,
            }}
          ></h3>
          <div className="mb-10">
            {isEauEau && (
              <p className="paragraph">
                {
                  this.props.dataItems[this.props.currentStepName]
                    .paragraphEauEau
                }
              </p>
            )}
            <p className="paragraph">
              {
                this.props.dataItems[this.props.currentStepName][
                  this.context?.user?.id ? "paragraphUser" : "paragraph"
                ]
              }
            </p>

            {this.props.nonPrimeHellio && (
              <p className="paragraph">
                La prime Sorégies pour le système solaire combiné ou la pompe à
                chaleur hybride n'est éligible qu'en cas de remplacement de
                chaudière
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Greeting;
