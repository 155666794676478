import React from "react";
import Navigation from "./Navigation";
import infoIcon from "../images/info.svg";
class QuestionQ22 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "none",
    };
  }

  showHover = () => {
    this.setState({ display: "block" });
  };

  hideHover = () => {
    this.setState({ display: "none" });
  };

  render() {
    const Q22 =
      this.props.Q45 == "PAC hybride air/eau"
        ? "Q22-pachybride"
        : this.props.Q45 == "Système solaire combiné"
          ? "Q22-solaire"
          : this.props.name;
    const itemsMobile = this.props.dataItems[Q22].items.map((item) => (
      <div className="item-container-m" key={item.long}>
        <label>
          <div
            className={
              this.props.data == item.short ? "item item-checked" : "item"
            }
          >
            <img src={item.icon} alt={item.short} width="30" height="35" />{" "}
            <span>{item.long}</span>
          </div>
          <input
            type="radio"
            name={this.props.name}
            value={item.short}
            onChange={this.props.handleData}
            className="is-hidden"
          />
        </label>
      </div>
    ));

    const items = this.props.dataItems[Q22].items.map((item) => (
      <div className="item-container" key={item.long}>
        <label>
          <div
            className={
              this.props.data == item.short ? "item item-checked" : "item"
            }
          >
            <div className="img">
              <img src={item.icon} alt={item.short} />
            </div>
            <div className="d-flex">
              <span
                className={this.props.data == item.short ? "text-sub2" : ""}
              >
                {item.long}
              </span>
              {item.tooltip && (
                <div
                  className="info-block-container is-hidden-mobile"
                  onMouseEnter={(e) => this.showHover(e)}
                  onMouseLeave={(e) => this.hideHover(e)}
                >
                  <div
                    className="iconColor1"
                    style={{ float: "right", marginLeft: "5px" }}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="7.5"
                        cy="7.5"
                        r="7"
                        fill="white"
                        stroke="#006FBA"
                      />
                      <path
                        d="M7.81726 3C8.00677 3 8.15905 3.05865 8.27411 3.17594C8.38917 3.28633 8.4467 3.43122 8.4467 3.61061C8.4467 3.79 8.38917 3.93834 8.27411 4.05563C8.15905 4.16602 8.00677 4.22122 7.81726 4.22122C7.63452 4.22122 7.48562 4.16602 7.37056 4.05563C7.26227 3.93834 7.20812 3.79 7.20812 3.61061C7.20812 3.43122 7.26227 3.28633 7.37056 3.17594C7.48562 3.05865 7.63452 3 7.81726 3ZM8.49746 10.3066H10V11H6V10.3066H7.64467V6.23933H6.05076V5.54593H8.49746V10.3066Z"
                        fill="#006FBA"
                      />
                    </svg>
                  </div>
                  {/* <img src={infoIcon} style={{float:'right', marginTop:'3px', marginRight:'20px'}} /> */}
                  <div
                    className="info-block info-block-bailleur"
                    style={{ display: this.state.display }}
                  >
                    <h3>Propriétaire bailleur : </h3>
                    <p className="mt-10">
                      <strong>Un bailleur</strong> désigne une personne physique
                      ou morale (banque, mutuelle, compagnie d'assurance),
                      généralement <strong>propriétaire</strong> d'un bien
                      immobilier et qui met celui-ci en location.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex mb-30">
            <div>
              <div>
                <input
                  type="radio"
                  name={this.props.name}
                  value={item.short}
                  onChange={this.props.handleData}
                  className="is-hidden"
                />
              </div>
            </div>
            <div className={item.long.length > 18 ? "lh16" : "lh22"}></div>
          </div>
        </label>
      </div>
    ));

    console.log("?", this.props.dataItems[this.props.name].items.length);
    return (
      <div className="fadeIn">
        <div
          className={
            this.props.dataItems[Q22].items.length > 3
              ? "item-wrapper is-hidden-mobile"
              : "item-wrapper jcc is-hidden-mobile"
          }
        >
          {items}
        </div>
        <div className="only-show-mobile">{itemsMobile}</div>

        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
          leadsource={this.props.leadsource}
          lastStepPrequestion={this.props.lastStepPrequestion}
        />
      </div>
    );
  }
}

export default QuestionQ22;
