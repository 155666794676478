import React from "react";
import Navigation from "./Navigation";
import QuestionsTextSalutation from "./QuestionsTextSalutation";
import Autocomplete from "react-google-autocomplete";
class QuestionsTextAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();
  }

  enterNext = (e) => {
    if (e.key === "Enter" && this.props.data) {
      this.props.nextStep();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.enterNext);
    if (!this.props.showAddress) {
      document.querySelectorAll("input")[0].focus();
    }
  }

  componentDidUpdate() {
    if (!this.props.showAddress) {
      document.querySelectorAll("input")[0].focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.enterNext);
  }

  render() {
    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="input-container flex-input" key={item.name}>
        <label
          className={
            item.name == "street"
              ? "mt-10"
              : this.props.showAddress
                ? "mt-10"
                : "is-hidden"
          }
          style={{ width: "90px" }}
        >
          {item.label}
        </label>
        <div className="mv-5">
          {item.name !== "street" && (
            <input
              type={item.type ? item.type : "text"}
              placeholder={item.placeholder}
              name={item.name}
              onChange={this.props.handleData}
              value={this.props.userContact[item.name]}
              maxLength={item.maxLength}
              minLength={item.minLength}
              onKeyPress={this.enterNext}
              className={this.props.showAddress ? "" : "is-hidden"}
            />
          )}

          {item.name == "street" && (
            <Autocomplete
              apiKey="AIzaSyAhT809cnid2Uqamc6Gk_6j-QP-oZuSqj0"
              options={{
                types: ["address"],
                componentRestrictions: { country: "fr" },
              }}
              onPlaceSelected={this.props.onPlaceSelected}
              className="w-100"
              name={item.name}
              data-name={this.props.name}
              onChange={this.props.handleData}
              value={this.props.userContact[item.name]}
              placeholder={item.placeholder}
            />
          )}
          <div className={this.props.onForm ? "" : "is-hidden"}>
            {item.validation == "codepostal" && (
              <div
                className={
                  this.props.codepostalValid
                    ? "is-hidden"
                    : "validate-error mt-10"
                }
              >
                Veuillez entrer un code postal valide
              </div>
            )}
          </div>
        </div>
      </div>
    ));

    return (
      <div className="fadeIn">
        <div className="w-100 d-flex jcc mt-20">
          <div style={{ minWidth: "360px" }} className="d-flex column">
            {this.props.dataItems[this.props.name].salutation && (
              <QuestionsTextSalutation
                data={this.props.dataItems[this.props.name].salutation}
                handleData={this.props.handleData}
                salutation={this.props.salutation}
              ></QuestionsTextSalutation>
            )}
            <div className="mb-50">{items}</div>
          </div>
        </div>

        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
        />
      </div>
    );
  }
}

export default QuestionsTextAutocomplete;
