import React from "react";
import Navigation from "./Navigation";
import QuestionsTextSalutation from "./QuestionsTextSalutation";
class QuestionsText extends React.Component {
  enterNext = (e) => {
    if (e.key === "Enter" && this.props.data) {
      this.props.nextStep();
    }
  };

  componentDidMount() {
    document.querySelectorAll('input:not(input[type="radio"])')[0].focus();

    if (this.props.name == "Q29") {
      this.props.handleAutofill();
    }
  }

  componentDidUpdate() {
    const items = document.querySelectorAll('input:not(input[type="radio"])');
    if (!items[0].value) {
      items[0].focus();
    }
  }

  render() {
    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="input-container flex-input" key={item.name}>
        <label className="mt-10" style={{ width: "90px" }}>
          {item.label}
        </label>
        <div className="mt-5">
          {item.type == "number" && (
            <input
              type={item.type ? item.type : "text"}
              placeholder={item.placeholder}
              name={item.name}
              onChange={this.props.handleData}
              value={this.props.userContact[item.name]}
              min={item.min}
              step={item.step}
              onKeyPress={this.enterNext}
              autoFocus
              disabled={this.props.readOnly}
            />
          )}

          {item.type !== "number" && (
            <input
              type={item.type ? item.type : "text"}
              placeholder={item.placeholder}
              name={item.name}
              onChange={this.props.handleData}
              value={this.props.userContact[item.name]}
              maxLength={item.maxLength}
              minLength={item.minLength}
              onKeyPress={this.enterNext}
              disabled={this.props.readOnly}
            />
          )}

          <div className={this.props.onForm ? "" : "is-hidden"}>
            {item.validation == "phone" && (
              <div
                className={
                  this.props.phoneValid ? "is-hidden" : "validate-error mt-10"
                }
              >
                Veuillez entrer un numéro valide
              </div>
            )}

            {item.validation == "email" && (
              <div
                className={
                  this.props.emailValid ? "is-hidden" : "validate-error mt-10"
                }
              >
                Veuillez entrer un email valide
              </div>
            )}

            {item.validation == "codepostal" && (
              <div
                className={
                  this.props.codepostalValid
                    ? "is-hidden"
                    : "validate-error mt-10"
                }
              >
                Veuillez entrer un code postal valide
              </div>
            )}
          </div>
        </div>
      </div>
    ));

    return (
      <div className="fadeIn">
        <div className="w-100 d-flex jcc">
          <div style={{ minWidth: "360px" }} className="d-flex column">
            {this.props.dataItems[this.props.name].salutation && (
              <QuestionsTextSalutation
                data={this.props.dataItems[this.props.name].salutation}
                handleData={this.props.handleData}
                salutation={this.props.salutation}
                readOnly={this.props.readOnly}
              ></QuestionsTextSalutation>
            )}

            <div className="mb-50">{items}</div>
          </div>
        </div>
        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
        />
      </div>
    );
  }
}

export default QuestionsText;
