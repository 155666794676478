import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserContext from "components/UserContext";
import getEnvironment from "utils/getEnvironment";

const { customerAccountUrl } = getEnvironment();

export default function CreateAccount({
  simulationId,
  simulationCode,
  contact,
}) {
  const { token } = useContext(UserContext) || {};

  if (!customerAccountUrl) {
    return null;
  }

  const simulationUrlParams = {
    simulationId,
    simulationCode,
  };
  const loginUrl = `${customerAccountUrl}/login?${new URLSearchParams(simulationUrlParams)}`;

  const createAccountUrlParams = {
    ...simulationUrlParams,
    title: contact?.salutation === "Madame" ? "Mme" : "M.",
    name: contact?.lastname,
    firstname: contact?.firstname,
    email: contact?.email,
    telephone: contact?.mobilephone,
  };
  const createAccountUrl = `${customerAccountUrl}/create-account?${new URLSearchParams(createAccountUrlParams)}`;

  return (
    !token && (
      <>
        <hr className="w-100 light-gray-hr" />
        <div className="flex flex-row pt-4">
          <div className="flex flex-1 text-xs h-12 items-center">
            {simulationCode ? (
              <>
                Pour finaliser votre projet, créez votre compte Sorégies&nbsp;:
              </>
            ) : (
              <>
                Pour finaliser votre projet, connectez-vous à votre espace
                bénéficiaire Sorégies&nbsp;:
              </>
            )}
          </div>
          <div className="flex flex-0 flex-col align-center">
            <a
              className="block btn-nav-m items-center flex"
              href={
                simulationCode
                  ? createAccountUrl
                  : `${customerAccountUrl}/mes-simulations`
              }
            >
              {simulationCode ? "Créer un compte" : "Je me connecte"}
            </a>
            {simulationCode && (
              <a className="block btn-login" href={loginUrl}>
                J&apos;ai déjà un compte
              </a>
            )}
          </div>
        </div>
      </>
    )
  );
}
CreateAccount.propTypes = {
  simulationId: PropTypes.number.isRequired,
  simulationCode: PropTypes.string.isRequired,
  contact: PropTypes.instanceOf(Object).isRequired,
};
