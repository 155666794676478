import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import getEnvironment from "utils/getEnvironment";

const { apiUrl, apiToken } = getEnvironment();

const UserContext = createContext();
export default UserContext;

/**
 * Fournit un contexte contenant les informations du client connecté.
 */
export function UserProvider({ children }) {
  const [searchParams] = useSearchParams();
  const tokenFromParams = searchParams.get("token") ?? "";
  const embed = searchParams.get("embed") === "true";

  const [user, setUser] = useState();
  const [token, setToken] = useState(tokenFromParams);

  // Définit le token à partir de celui fourni en URL
  useEffect(() => {
    if (token !== tokenFromParams) {
      setToken(tokenFromParams);
    }
  }, [token, tokenFromParams]);

  // Met à jour l'utilisateur lorsque le token a changé
  const updateUser = useCallback(async () => {
    if (token) {
      let fetchedUser = {};
      try {
        const res = await fetch(`${apiUrl}/getuser?token=${token}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: apiToken,
          },
        });
        const data = await res.json();
        if (data.success === "false") {
          throw new Error(data.error);
        }
        fetchedUser = data.data;
      } catch (e) {
        console.warn(e);
      }
      setUser(fetchedUser);
    }
  }, [token]);

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  const contextValue = useMemo(
    () => ({ user, token, embed }),
    [user, token, embed],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
