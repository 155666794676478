import React from "react";
import dataSuccess from "../data/dataSuccess";
import SuccessNormal from "./success/SuccessNormal";
import SuccessComment from "./success/SuccessComment";
import SuccessBorne from "./success/SuccessBorne";
import SuccessAudit from "./success/SuccessAudit";
import SuccessPhotovoltaique from "./success/SuccessPhotovoltaique";
// import SuccessList from './success/SuccessList';
import SuccessCopro from "./success/SuccessCopro";
import SuccessFile from "./success/SuccessFile";
import Loader from "./Loader";
import ErrorHandler from "./ErrorHandler";
import { thisExpression } from "@babel/types";

class Success extends React.Component {
  constructor(props) {
    super(props);
  }

  renderSuccess(current) {
    switch (dataSuccess[current].template) {
      case "normal":
        return <SuccessNormal {...this.props} />;
      case "audit":
        return <SuccessAudit {...this.props} />;
      case "comment":
        return <SuccessComment {...this.props} />;
      case "file":
        return <SuccessFile {...this.props} />;
      case "photovoltaique":
        return <SuccessPhotovoltaique {...this.props} />;
      case "borne":
        return <SuccessBorne {...this.props} />;
      case "copro":
        return <SuccessCopro {...this.props} />;
      default:
        return <h1>{this.props.success}</h1>;
    }
  }

  componentDidMount() {
    this.props.simulationAPI();
  }

  render() {
    if (this.props.loading) {
      return <Loader></Loader>;
    } else if (this.props.error) {
      console.log(this.props.error);
      return <ErrorHandler reset={this.props.reset}></ErrorHandler>;
    } else if (
      (this.props.success == "AuditEnergetique" &&
        this.props.Q48 == "Audit énergétique" &&
        this.props.surface > 350) ||
      (this.props.success == "AuditEnergetique" &&
        this.props.Q48 == "Audit règlementaire" &&
        this.props.surface > 450) ||
      (this.props.success == "borneDeRecharge" &&
        !this.props.result.data["Coût travaux unitaire (€)"])
    ) {
      return (
        <div>
          <SuccessComment {...this.props} />
        </div>
      );
    } else if (
      this.props.success == "RenovationGlobale" &&
      this.props.surface > 350
    ) {
      return (
        <div>
          <SuccessComment {...this.props} />
        </div>
      );
    } else {
      return (
        <div>
          {dataSuccess[this.props.success] && (
            <div>{this.renderSuccess(this.props.success)}</div>
          )}

          {!dataSuccess[this.props.success] && (
            <div className="p-50">
              <h1>{this.props.success}</h1>
            </div>
          )}
        </div>
      );
    }
  }
}

export default Success;
