import React from "react";
import dataSuccess from "data/dataSuccess";
import MaPrimeRenovBlock from "components/success/MaPrimeRenovBlock";
import ErrorHandler from "components/ErrorHandler";
import NavSuccess from "components/success/NavSuccess";
import SuccessTemplateSidebar from "components/success/SuccessTemplateSidebar";
import FileUploaderAuto from "components/success/FileUploaderAuto";
import PrimeBlock from "components/success/PrimeBlock";
import PoitouCheckbox from "components/success/PoitouCheckbox";
import CreateAccount from "components/success/CreateAccount";

class SuccessNormal extends React.Component {
  render() {
    const bgMobile = `url(${dataSuccess[this.props.success].imgMobile})`;

    if (this.props.result.success == "false") {
      return (
        <ErrorHandler
          message={this.props.result.message}
          reset={this.props.reset}
        ></ErrorHandler>
      );
    } else {
      const APIresult = this.props.result.data;
      console.log(APIresult.Offre);
      const MaPrimeRenov = APIresult["Montant MPR unitaire (€)"];
      const PrimeHellio = APIresult["Montant prime CEE unitaire (€)"];
      const price1 = this.props.answers.Q43
        ? new Intl.NumberFormat("fr-FR", { currency: "EUR" }).format(
            this.props.answers.Q43,
          )
        : APIresult["Coût travaux unitaire (€)"];
      const price2 = MaPrimeRenov || PrimeHellio;
      const price3 = APIresult["Reste à charge unitaire (€)"]
        ? APIresult["Reste à charge unitaire (€)"]
        : 0;
      const CDP = APIresult.CDP;
      const CDB = APIresult.CDB;
      const mentionFenetre =
        APIresult.Offre === "isolation des fenêtres ou porte-fenêtre";
      // const deposeChaudiere = ["pompe à chaleur air/eau", "pompe à chaleur eau/eau", "chaudière biomasse", "pompe à chaleur hybride", "système solaire combiné"].includes(APIresult["Offre"])
      const simulerTravaux = this.props.answers.Q30 === "simulationTravaux";
      const primeS = this.props.answers.Q30 === "primeHellio";
      const postalCode = this.props.answers?.Q26?.postalcode;
      const inDep =
        postalCode &&
        (postalCode.slice(0, 2) === "86" || postalCode.slice(0, 2) === "37");
      console.log(inDep);
      // console.log('deposeChaudiere', deposeChaudiere, 'APIresult offre', APIresult["Offre"] )
      const deposeChaudiereMention =
        CDB && !CDP && !primeS && mentionFenetre
          ? "Cette estimation inclut la dépose de l'ancienne chaudière, la fourniture et la pose de la nouvelle chaudière ainsi que la mise en conformité de la chaufferie. Les montants de travaux et de primes peuvent varier en fonction de la surface chauffée, de la zone géographique du chantier, ou des revenus du ménage. Un artisan partenaire RGE précisera ces estimations ainsi que la faisabilité technique du chantier lors d'une visite technique gratuite et sans engagement."
          : !CDB && CDP && !primeS && mentionFenetre
            ? "Cette estimation inclut la dépose de l'ancienne chaudière, la fourniture et la pose du matériel ainsi que la mise en conformité de la chaufferie. Les montants de travaux et de primes peuvent varier en fonction de la surface chauffée, de la zone géographique du chantier, ou des revenus du ménage. Un artisan partenaire RGE précisera ces estimations ainsi que la faisabilité technique du chantier lors d'une visite technique gratuite et sans engagement."
            : !primeS && !mentionFenetre
              ? "Cette estimation inclut la fourniture et la pose du matériel ainsi que la mise en conformité de la chaufferie. Les montants de travaux et de primes peuvent varier en fonction de la surface chauffée, de la zone géographique du chantier, ou des revenus du ménage. Un artisan partenaire RGE précisera ces estimations ainsi que la faisabilité technique du chantier lors d'une visite technique gratuite et sans engagement."
              : "";
      const isFenetre =
        APIresult.Offre === "isolation des fenêtres ou porte-fenêtre";
      const mentionWithPrime =
        "Ces montants de primes sont des estimations, les aides financières sont susceptibles de varier selon le type de matériel installé. Sorégies confirmera votre montant de prime avec votre devis et votre revenu fiscal de référence validés";
      const mention = !PrimeHellio
        ? "La prime Sorégies pour la pompe à chaleur eau/eau n'est éligible que pour les maisons individuelles construites il y a plus de deux ans."
        : deposeChaudiereMention;
      // : `${dataSuccess[this.props.success]['paragraph']} ${dataSuccess[this.props.success]['paragraphBold'] ? dataSuccess[this.props.success]['paragraphBold']: ''}`;

      return (
        <div className="flex-container">
          <SuccessTemplateSidebar
            success={this.props.success}
            lastStep={this.props.lastStep}
            codePromo={this.props.codePromo}
            handleCodePromo={this.props.handleCodePromo}
            validCodePromo={this.props.validCodePromo}
            resultCodePromo={this.props.resultCodePromo}
            isPrime={this.props.isPrime}
          >
            <div className="success-item">
              <h3>Type de logement</h3>
              <h2>{this.props.Q2}</h2>
            </div>
            {this.props.surface && !this.props.isPrime && (
              <div className="success-item">
                <h3>Surface du logement </h3>
                <h2>
                  {" "}
                  {this.props.surface} m
                  <sup style={{ fontSize: "0.7em" }}>2</sup>{" "}
                </h2>
              </div>
            )}

            {this.props.Q47 && this.props.isPrime && (
              <div className="success-item">
                <h3>Surface chauffée </h3>
                <h2>
                  {" "}
                  {this.props.Q47} m<sup style={{ fontSize: "0.7em" }}>2</sup>{" "}
                </h2>
              </div>
            )}

            <div className="success-item">
              <h3>Système de chauffage principal</h3>
              <h2>{this.props.Q22}</h2>
            </div>
            <MaPrimeRenovBlock
              color={this.props.result.data["Couleur MPR"]}
            ></MaPrimeRenovBlock>

            {this.props.Q46 && (
              <div className="success-item">
                <h3>Nombre de fenêtres</h3>
                <h2>{this.props.Q46}</h2>
              </div>
            )}
          </SuccessTemplateSidebar>
          <div className="success-right-container">
            <div className="success-item-right mt-5">
              <h5>
                {this.props.success == "AuditEnergetique" ||
                this.props.success == "RenovationGlobale"
                  ? "Coût de l’audit énergétique"
                  : "Estimation du coût de mes travaux"}
              </h5>
              <div>
                <h5 className="h5-price"> {price1} € </h5>
              </div>
            </div>
            <PrimeBlock
              APIresult={APIresult}
              success={this.props.success}
              resultCodePromo={this.props.resultCodePromo}
              nonPrimeHellio={this.props.nonPrimeHellio}
            ></PrimeBlock>
            {/* Normal reste a Payer */}
            {price3 !== 0 &&
              price2 &&
              !this.props.resultCodePromo.data.reste_a_charge && (
                <div className="success-item-right last">
                  <h5>Reste à payer</h5>
                  <div>
                    <h5 className="h5-price"> {price3} € </h5>
                  </div>
                </div>
              )}
            {/* reste a Payer avec Code promo */}
            {price3 !== 0 && this.props.resultCodePromo.data.reste_a_charge && (
              <div className="success-item-right last">
                <h5>Reste à payer</h5>
                <div>
                  <h5 className="h5-price">
                    {" "}
                    {this.props.resultCodePromo.data.reste_a_charge} €{" "}
                  </h5>
                </div>
              </div>
            )}
            {/* {this.props.nonPrimeHellio &&
            <p className="paragraph">La prime Sorégies n'est éligible qu'en cas de remplacement de chaudière.</p>
          } */}
            {this.props.showFile && price2 && (
              <FileUploaderAuto
                patchName="fileImposition"
                description="Déposez votre avis imposition pour valider le montant de votre prime"
                handleFile={this.props.handleFile}
                result={this.props.result}
              ></FileUploaderAuto>
            )}
            <NavSuccess
              callBtn={this.props.callBtn}
              lastStep={this.props.lastStep}
              date={this.props.date}
              creneau={this.props.creneau}
              handleDate={this.props.handleDate}
              handleCreneau={this.props.handleCreneau}
            ></NavSuccess>
            {simulerTravaux && inDep && (
              <h5 className="paragraph mv-10">
                <a
                  href="https://www.soregies.fr/concretiser-vos-travaux-partenaires-rge/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-main"
                >
                  J'ai besoin d'une entreprise de travaux RGE
                </a>
              </h5>
            )}
            {isFenetre && price2 && (
              <p className="paragraph mv-10">
                La prime est estimée sur la base d’une surface moyenne de
                fenêtre
              </p>
            )}
            <p className="paragraph">{mention}</p>
            {PrimeHellio && (
              <p className="paragraph mt-10">{mentionWithPrime}</p>
            )}
            <PoitouCheckbox
              answers={this.props.answers}
              result={this.props.result}
              bodyData={this.props.bodyData}
            ></PoitouCheckbox>
            {/* {!this.props.isPrime && !deposeChaudiere && !this.props.nonPrimeHellio &&
            <p className="paragraph">
              {dataSuccess[this.props.success]['paragraph']} <br />
              <span className="is-bold">
                {dataSuccess[this.props.success]['paragraphBold']}
              </span>
            </p>
          } */}

            <CreateAccount
              simulationId={this.props.simulationId}
              simulationCode={this.props.simulationCode}
              contact={this.props.contact}
            />
          </div>
          <div
            style={{
              backgroundImage: bgMobile,
            }}
            className="success-header-img only-show-mobile"
          >
            <h1>{dataSuccess[this.props.success].h1}</h1>

            <button className="btn-init-success" onClick={this.props.reset}>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4697_13755)">
                  <path
                    d="M13.3083 2.97206C13.4457 3.18957 13.5756 3.38159 13.6888 3.57816C14.2172 4.47858 14.5306 5.47976 14.6065 6.50979C14.6824 7.53982 14.519 8.57316 14.1279 9.53559C13.7368 10.498 13.1278 11.3657 12.3446 12.0762C11.5613 12.7868 10.6234 13.3227 9.59799 13.6453C5.59089 14.9267 1.21656 12.6169 0.214785 8.69046C-0.741326 4.9419 1.57998 1.21063 5.481 0.226846C6.06199 0.0832859 6.65906 0.0078038 7.25908 0.00205981C7.63201 -0.00385562 7.90886 0.236857 7.91504 0.567211C7.92123 0.897565 7.64961 1.14237 7.27478 1.14874C5.44485 1.17969 3.90604 1.83903 2.7021 3.15316C1.51766 4.44546 1.02011 5.97073 1.2275 7.67892C1.54716 10.2899 3.55832 12.3162 6.2497 12.7839C9.54566 13.3559 12.7741 11.1945 13.3373 8.03476C13.6033 6.54649 13.319 5.16031 12.4844 3.87621C12.4402 3.80796 12.3921 3.74198 12.3198 3.63777C12.3079 3.81023 12.297 3.94265 12.2903 4.07551C12.277 4.34398 12.268 4.61336 12.2532 4.88183C12.2511 4.95684 12.2333 5.03068 12.2009 5.099C12.1685 5.16732 12.1221 5.22874 12.0644 5.27963C12.0068 5.33052 11.9391 5.36986 11.8653 5.39532C11.7915 5.42078 11.7132 5.43185 11.6348 5.42787C11.5576 5.42661 11.4813 5.41054 11.4106 5.38061C11.3399 5.35069 11.2762 5.30751 11.2233 5.25364C11.1704 5.19977 11.1292 5.13629 11.1024 5.06696C11.0755 4.99763 11.0634 4.92385 11.0669 4.84998C11.0987 3.97222 11.1344 3.09446 11.1806 2.21716C11.2024 1.79853 11.4845 1.58785 11.9378 1.642C12.6904 1.73301 13.4415 1.83266 14.1935 1.92867C14.2986 1.94187 14.4042 1.95324 14.5084 1.97144C14.8266 2.02605 15.0274 2.28997 14.9931 2.60348C14.9786 2.74429 14.9078 2.8743 14.7955 2.96638C14.6832 3.05846 14.5381 3.10549 14.3904 3.09765C14.095 3.07945 13.8011 3.0303 13.5066 2.99481C13.4524 2.98753 13.3948 2.98162 13.3083 2.97206Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4697_13755">
                    <rect width="15" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      );
    }
  }
}

export default SuccessNormal;
