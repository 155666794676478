const dataPhotovoltaique = {
  "photovoltaïque 3kwc": {
    pack: 3,
    panneaux: 8,
    packName: "efficace",
  },
  "photovoltaïque 4.5kwc": {
    pack: "4,5",
    panneaux: 12,
    packName: "flexible",
  },
  "photovoltaïque 6kwc": {
    pack: 6,
    panneaux: 16,
    packName: "performant",
  },
  "photovoltaïque 7.5kwc": {
    pack: "7,5",
    panneaux: 20,
    packName: "puissant",
  },
  "photovoltaïque 9kwc": {
    pack: 9,
    panneaux: 24,
    packName: "premium",
  },
};
export default dataPhotovoltaique;
