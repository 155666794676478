import React from "react";
import dataMPRcolors from "../../data/dataMPRcolors";
class MaPrimeRenovBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="success-item">
        <h3>MaPrimeRénov’</h3>
        <h4
          style={{ backgroundColor: dataMPRcolors[this.props.color].bg }}
          className="MaPrimeTag"
        >
          Ménage {dataMPRcolors[this.props.color].display}
        </h4>
      </div>
    );
  }
}

export default MaPrimeRenovBlock;
